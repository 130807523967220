import React, { Component } from 'react';
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';


import Signin from './components/auth/Signin';
import Navbar from './Layout/Navbar';
import Main from './components/Main';
import { FetchPostsApi } from './store/actions/PostsAction';
import SinglePost from './components/SinglePost';
import Category from './components/Category';
import Categories from './components/Categories';
import Footer from './Layout/Footer';
import Terms from './components/Terms';
// import AddUser from './components/auth/AddUser';
import Site from './components/Site';
import CategoryNav from './Layout/CategoryNav'
import TermsNav from './Layout/TermsNav'

class App extends Component {

    componentDidMount = () => {
        this.props.fetchPosts();

    }
    render() {
        return (
            <BrowserRouter>
                <div className="App">
                    <Navbar />
                    <div className="app-wrap">
                        <CategoryNav />
                        <Switch>
                            <Route path={'/'} exact component={Main} />
                            <Route path={'/signin'} exact component={Signin} />
                            {/* <Route path={'/add-user'} exact component={AddUser} /> */}
                            <Route path={'/category'} exact component={Categories} />
                            <Route path={'/site'} exact component={Site} />

                            <Route path={'/category/:category_slug'} exact component={Category} />
                            <Route path={'/terms/:terms_slug'} exact component={Terms} />
                            <Route path={'/:post_slug'} exact component={SinglePost} />
                        </Switch>
                    </div>
                    <Footer />
                    <TermsNav />
                </div>
            </BrowserRouter>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchPosts: () => {
            dispatch(FetchPostsApi());
        }
    }
}
export default connect(null, mapDispatchToProps)(App);
