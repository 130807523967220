import React from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

function Terms(props) {
    console.log("break");
    if (!props.post) {
        return null;
    }

    return (
        <div>
            {props.post && <h1>{props.post.title}</h1>}
            {
                props.post.content &&
                <div>
                    <p dangerouslySetInnerHTML={{ __html: props.post.content }}></p>
                    <p><span className="date">{new Date(props.post.created_at).toUTCString()}</span></p>
                </div>
            }
        </div>
    )
}
const mapStateToProps = (state, ownProps) => {

    let slug = ownProps.match.params.terms_slug;
    console.log("omg" + slug);
    return {
        post: state.posts.terms.find(item => item.slug === slug)
    }
}
export default connect(mapStateToProps)(Terms);