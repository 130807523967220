export const FetchPostsApi = () => {
    return (dispatch) => {
        const Cosmic = require('cosmicjs')
        var api = new Cosmic();
        var bucket = api.bucket({
            slug: '5c86dde0-026f-11eb-b22e-8361b00cae17',
            read_key: '8OcX7cLYNZc622PzOC1T1Zn3afvBeXyPAXEH5p2pJCDVhvOT8j'
        })
        bucket.getObjects().then(data => {
            dispatch({ type: "FETCH_POSTS", data: data });
        }).catch(err => {
            console.log(err)
        })

    }
}