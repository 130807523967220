import React from 'react'
import { connect } from 'react-redux';
import CategoryNav from './../Layout/CategoryNav'
import '../css/SinglePost.css';
import moment from 'moment';
import { Link } from 'react-router-dom';
import failedImage from '../assets/images/BagsAndBoxes_16.png';

function SinglePost(props) {

    return (
        <div>
            <div className="page-container">
                <div className="page-wrap" style={{ backgroundImage: `url(${props.post && (props.post.metadata.image.url ? props.post.metadata.image.url : failedImage)})` }}>
                    
                    {props.post && <h1 className="post-headline">{props.post.title}</h1>}
                </div>

                {props.post && <h2 className="post-subtitle">{props.post.metadata.snipped}</h2>}
                <h3 className="post-details">
                    {props.post && <span>Posted: {moment(props.post.created_at).format("MMM Do YYYY")} in <Link to={'/category/' + props.post.metadata.category.slug}>{props.post.metadata.category.slug}</Link>
                        &nbsp;(last updated: {moment(props.post.modified_at).format("MMM Do YYYY")})</span>}
                </h3>
                
                {props.post && <p className="text-page" dangerouslySetInnerHTML={{ __html: props.post.content }}></p>}
                {
                    //<div className="side-bar">
                    //    <div className="vote-container">
                    //        <i className="fa fa-thumbs-o-up fa-2x" aria-hidden="true"></i>
                    //        <span className="vote">(22) </span>
                    //        {/* required paiment plan to add post request to cosmic */}
                    //    </div>
                    //</div>
                }
            </div>

            {props.post && <p>
                <Link to={'/category/' + props.post.metadata.category.slug}>See more {props.post.metadata.category.slug} articles.</Link>
            </p>}

        </div>
    )
}
const mapStateToProps = (state, ownProps) => {
    console.log("wtf!");

    let slug = ownProps.match.params.post_slug;
    return {
        post: state.posts.posts.find(item => item.slug === slug)
    }
}
export default connect(mapStateToProps)(SinglePost);