import React from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CategoryNav from './../Layout/CategoryNav'

import '../css/Category.css';

 function Category(props) {
    
  return (

      <div>
          <h1>{props.categoryName} Articles</h1>
       {
          props.postCategory &&  props.postCategory.map((item, index) => {
            return(
            <div key={index} className="main-category-wrap">
            {item && <img className="category-image" src={item.metadata.image.url} alt={item.slug}/> }
           <div className="category-text-wrap">
           <Link to={'/' + item.slug}> <h2>{item.title}</h2></Link> 
           { item.length < 300 ? 
           <p dangerouslySetInnerHTML={{__html:item.content}}></p> :
           <p dangerouslySetInnerHTML={{__html:item.content.substring(0, 300) + '...'}}></p> 
                        }
                        <p><span className="date">{new Date(item.created_at).toUTCString()}</span></p>
           </div>
            </div>
            )

          })
          }
    </div>
  )
}
const mapStateToProps=(state, ownProps) => {
    if(ownProps.match) {
        var slug = ownProps.match.params.category_slug;
    } else{
        var main_slug = 'game-dev'
    }

    let categoryName = "Uncategorised"

    let postCategory = state.posts.posts.filter(cat => {


        if (slug && cat.metadata.category.slug === slug) {
            categoryName = cat.metadata.category.title
            return slug
        }
        else {
            return cat.metadata.category.slug === main_slug
        }

    });

    let category

    return{
        postCategory: postCategory,
        categoryName: categoryName
    }
}
export default connect(mapStateToProps)(Category);