import React from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
function TermsNav(props) {
    return (
        <div className="category-wrap">
            <ul className="category-list">
                {
                    props.terms && props.terms.map((term, key) => {
                        return (
                            <Link key={key} to={'/terms/' + term.slug}> <li className="item">{term.title}</li> </Link>
                        )
                    })
                }
            </ul>
        </div>
    )
}
const mapStateToProps = (state) => {

    return {
        terms: state.posts.terms
    }
}
export default connect(mapStateToProps)(TermsNav)