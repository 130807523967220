import React from 'react'

import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <div className="footer">
            <p>&#169; 2020-2021 Tsunami Systems</p> 
        </div>
    )
}
